import { OAUTH_ENV_URL } from './utils/env';
import { isQyWeapp, isWeapp } from './utils/lang';
import { request, envConfig, setStaffAccountId, setStaffAccessToken, staffRequest } from './utils/request';
import wxWeapp from './WxWeapp';

/**
 * 员工类
 */
class Staff {
  /**
   * 当前渠道 ID
   *
   * @private
   * @type {string}
   */
  _channelId = '';

  /**
   * 当前渠道 openId
   *
   * @private
   * @type {string}
   */
  _openId = '';

  /**
   * 创建员工实例（单例）
   */
  constructor() {
    const { instance } = Staff;
    if (instance) {
      return instance;
    }
    Staff.instance = this;
    return this;
  }

  /**
   * 员工 OAuth
   *
   * @param {object} options - 选项
   * @param {string} [options.weappId] - 小程序的 appId
   * @param {string} [options.appId] - 企业微信应用 agentId
   * @param {string} [options.corpId] - 企业微信的 cropId
   * @param {string} [options.code] - 企业微信小程序[用户登录凭证](https://open.work.weixin.qq.com/api/doc/90000/90136/91506)
   * @param {string} [options.accessToken] - 用户令牌，标识当前授权的 Staff
   * @param {string} [options.accountId] - 租户 Id，用于集团租户登录
   *
   * @returns {Promise<void>}
   */
  async signin(options = {}) {
    let accountId = options.accountId || envConfig.accountId;
    // 微信公众号 H5 或 企业微信 H5
    if (options.accessToken) {
      this._accessToken = options.accessToken;
      setStaffAccessToken(options.accessToken);
      if (options.accountId) {
        setStaffAccountId(accountId);
      }
      return;
    }

    if (isWeapp) {
      if (isQyWeapp) {
        if (!options.weappId && (!options.appId || !options.corpId)) {
          throw new Error('appId and corpId are required or weappId is required');
        }
        const data = {
          weappId: options.weappId,
          appId: options.appId,
          corpId: options.corpId,
          code: options.code,
        };
        if (!data.code) {
          const { code } = await wxWeapp.qy.login();
          data.code = code;
        }
        let baseURL = OAUTH_ENV_URL[envConfig.env];
        if (accountId) {
          baseURL = `${OAUTH_ENV_URL[envConfig.env]}/${accountId}`;
        }
        const auth = await request.post('/v2/work/weapp/oauth', data, { baseURL });
        if (!accountId) {
          accountId = auth.accountId;
          setStaffAccountId(accountId);
        }

        setStaffAccessToken(auth.accessToken);
        this._accessToken = auth.accessToken;
        this._channelId = auth.channelId;
        this._openId = auth.openId;
      }
    }
  }

  /**
   * 获取 mai.staffRequest 租户 Id
   *
   * @returns {string}
   */
  getAccountId() {
    return staffRequest.defaults.headers['X-Account-Id'];
  }

  /**
   * 设置 mai.staffRequest 租户 Id
   * @param {string} accountId
   */
  setAccountId(accountId) {
    setStaffAccountId(accountId);
  }

  /**
   * 获取 channelId，需要授权 {@link Staff#signin}
   *
   * @returns {string}
   */
  getChannelId() {
    return this._channelId;
  }

  /**
   * 获取员工当前渠道 openId，需要授权 {@link Staff#signin}
   *
   * @returns {string}
   */
  getOpenId() {
    return this._openId;
  }

  /**
   * 获取 accessToken，需要授权 {@link Staff#signin}
   *
   * @private
   * @returns {string}
   */
  getAccessToken() {
    return this._accessToken;
  }
}

export default Staff;
