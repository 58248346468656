import qs from 'qs';
import { isWeapp } from './lang';

const getUtmFromUrl = () => {
  const utmFields = ['utmSource', 'utmContent', 'utmMedium', 'utmCampaign', 'utmTerm'];
  let query = {};
  if (isWeapp) {
    // eslint-disable-next-line prefer-destructuring
    query = wx.getLaunchOptionsSync().query;
  } else {
    query = {
      ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
      ...qs.parse(window.location.hash.replace(/^#[^?]*\?/, '')),
    };
  }
  const utmProperties = {};
  utmFields.forEach((field) => {
    if (query[field]) {
      utmProperties[field] = query[field];
    }
  });
  return utmProperties;
};

const getUtmFromScene = () => {
  if (isWeapp) {
    const { scene } = wx.getLaunchOptionsSync();
    switch (scene) {
      case 1005: case 1006: case 1027: case 1042: case 1053:
        return { utmSource: `search_${scene}`, utmMedium: '搜索' };
      case 1001: case 1010: case 1023: case 1024: case 1026: case 1089:
      case 1090: case 1106: case 1113: case 1114: case 1131: case 1142:
      case 1152: case 1157: case 1100: {
        let utmSource = `quick_entry_${scene}`;
        if (scene === 1152) {
          utmSource = `quick_entry_video_${scene}`;
        }
        if (scene === 1157) {
          utmSource = `quick_entry_profile_${scene}`;
        }
        if (scene === 1100) {
          utmSource = `quick_entry_cover_${scene}`;
        }
        return { utmSource, utmMedium: '快捷入口' };
      }
      case 1035: case 1043: case 1058: case 1074: case 1082: case 1091: case 1102: {
        let utmSource = `wechat_oa_${scene}`;
        if (scene === 1035) {
          utmSource = `wechat_oa_menu_${scene}`;
        }
        if (scene === 1043) {
          utmSource = `wechat_oa_tm_${scene}`;
        }
        if (scene === 1058) {
          utmSource = `wechat_oa_article_${scene}`;
        }
        if (scene === 1074) {
          utmSource = `wechat_oa_card_${scene}`;
        }
        return { utmSource, utmMedium: '公众号' };
      }
      case 1039: case 1045: case 1046: case 1060: case 1067: case 1077: case 1078: case 1079: case 1084: case 1095: {
        let utmSource = `wechat_ad_${scene}`;
        if (scene === 1045) {
          utmSource = `wechat_ad_moment_${scene}`;
        }
        if (scene === 1067) {
          utmSource = `wechat_ad_oa_${scene}`;
        }
        return { utmSource, utmMedium: '广告' };
      }
      case 1119: case 1120: case 1121: {
        let utmSource = `wechat_wework_workshop_${scene}`;
        if (scene === 1120) {
          utmSource = `wechat_wework_profile_${scene}`;
        }
        if (scene === 1121) {
          utmSource = `wechat_wework_chat_ext_${scene}`;
        }
        return { utmSource, utmMedium: '企业微信' };
      }
      case 1037: case 1038: case 1135:
        return { utmSource: `other_wechat_mp_${scene}`, utmMedium: '小程序' };
      case 1014: case 1073: case 1081: case 1107: case 1088: {
        let utmSource = `notification_${scene}`;
        if (scene === 1014) {
          utmSource = `notification_tm_${scene}`;
        }
        return { utmSource, utmMedium: '服务通知' };
      }
      case 1065:
        return { utmSource: `urlscheme_${scene}`, utmMedium: '链接' };
      case 1011: case 1012: case 1013: case 1025: case 1031: case 1032: case 1047:
      case 1048: case 1049: case 1124: case 1125: case 1126: case 1150: case 1153:
        return { utmSource: `scan_${scene}`, utmMedium: '扫码' };
      case 1007: case 1008: case 1036: case 1044: case 1096:
        return { utmSource: `chat_${scene}`, utmMedium: '微信聊天' };
      case 1019: case 1034: case 1057: case 1071: case 1072: case 1097:
        return { utmSource: `pay_${scene}`, utmMedium: '支付' };
      case 1029: case 1052: case 1128: case 1148: case 1028:
        return { utmSource: `coupon_package_${scene}`, utmMedium: '卡包' };
      case 1056: case 1064: case 1069: case 1092: case 1133: case 1146:
      case 1154: case 1155: case 1158: case 1167: case 1169:
        return { utmSource: `wechat_entry_${scene}`, utmMedium: '其他' };
      case 1017: case 1030: case 1059: case 1099: case 1129:
        return { utmSource: `test_${scene}`, utmMedium: '其他' };
      case 1000:
        return { utmSource: `others_${scene}`, utmMedium: '其他' };
      default:
        return { utmSource: `others_${scene}`, utmMedium: '其他' };
    }
  }
  return {};
};

const getUtmProperties = () => ({ ...getUtmFromScene(), ...getUtmFromUrl() });

export default {
  ...getUtmProperties(),
};
