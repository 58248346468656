import { getAccessToken } from './utils/accessToken';
import { createCacheStore } from './utils/cache';
import { isWeapp, isObject, isFunction } from './utils/lang';
import { request, envConfig, setAccessToken, getClientId, setAccountId } from './utils/request';
import wxWeapp from './WxWeapp';
import { WeappAuthScope } from './Enums';

/**
 * 客户类
 */
class Member {
  /**
   * 当前客户信息
   *
   * @private
   * @type {object}
   */
  _member = null;

  /**
   * 当前渠道 ID
   *
   * @private
   * @type {object}
   */
  _social = null;

  /**
   * access token，优先级低于 cookie 中的 accessToken
   *
   * @private
   * @type {string}
   */
  _accessToken = '';

  /**
   * 当前渠道是否第一次授权 newChannel
   *
   * @private
   * @type {boolean}
   */
  _newChannel = false;

  /**
   * 缓存客户数据
   *
   * @private
   * @type {function}
   */
  _getCache = createCacheStore()

  /**
   * 创建客户实例(单例)
   */
  constructor() {
    const { instance } = Member;
    if (instance) {
      return instance;
    }
    Member.instance = this;
    return this;
  }

  /**
   * OAuth 且把当前客户与该 client 的匿名事件绑定
   *
   * @param {object} options - 选项
   * @param {string} [options.accessToken] - 用户令牌，标识当前授权的用户
   * @param {string} [options.appId] - 微信小程序 app id，仅支持微信小程序。如果最低版本大于 2.2.2 可以不传
   * @param {WeappAuthScope} [options.scope='base'] - 微信小程序授权类型，仅支持微信小程序
   * @param {string} [options.code] - 微信小程序[用户登录凭证](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/login/wx.login.html)，仅支持微信小程序。在微信小程序插件中该参数必填
   * @param {string} [options.iv] - 微信小程序[加密算法的初始向量](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserInfo.html)，仅支持微信小程序。在微信小程序插件中 options.code = userinfo 时该参数必填
   * @param {string} [options.encryptedData] - 微信小程序[包括敏感数据在内的完整用户信息的加密数据](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserInfo.html)，仅支持微信小程序。在微信小程序插件中 options.code = userinfo 时该参数必填
   * @param {string} [options.lang='zh_CN'] - 微信小程序[显示用户信息的语言](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserInfo.html)，仅支持微信小程序
   * @param {boolean} [options.isGroup=false] - 集团登录，仅支持微信小程序
   * @param {string} [options.accountId] - 指定 accountId，仅支持微信小程序，用于集团租户登录
   * @param {string} [options.phone] - 手机号，仅支持通过手机号登录。如果账号未注册会自动注册并登录
   * @param {string} [options.phoneCode] - 手机验证码，仅支持通过手机号登录
   * @param {string} [options.source] - 注册来源，仅支持通过手机号登录
   * @param {boolean} [options.isActivated=false] - 客户会员卡激活状态，仅支持通过手机号登录
   *
   * @example
   * // 微信小程序
   * const mai = new Mai(env, accountId);
   * await mai.member.signin({scope: mai.WeappAuthScope.BASE, appId});
   *
   * @example
   * // H5 公众号授权，完成群脉公众号授权后 url query 后会携带 accessToken 参数
   * const mai = new Mai(env, accountId);
   * const { accessToken } = mai.qs.parse(location.search, { ignoreQueryPrefix: true });
   * await mai.member.signin({ accessToken });
   *
   * @example
   * // H5 手机号验证码登录
   * try {
   *   let phone = '18000000000';
   *   const captcha = new mai.Captcha();
   *   await captcha.sendSmsVerificationCode(phone);
   *   let code = '111111'; // 消费者收到的手机验证码，一般为 6 位数字。
   *   await mai.member.signin({ phone, code })
   * } catch (error) {
   *   if (error.ret === mai.Captcha.Ret.USER_CLOSED) {
   *     console.log('人机验证弹窗被关闭');
   *   } else {
   *     console.log('短信验证码发送失败');
   *   }
   * }
   *
   * @returns {Promise<void>}
   */
  async signin(options = {}) {
    const accessToken = getAccessToken(options);
    const accountId = options.accountId || envConfig.accountId;
    if (options.accountId) {
      setAccountId(options.accountId);
    }

    if (accessToken) {
      setAccessToken(accessToken);
      this._accessToken = accessToken;
      const { member, social } = await request.get('/v2/socialMember');
      this._member = member;
      this._social = social;
    } else if (options.phone) {
      const data = {
        phone: options.phone,
        code: options.phoneCode,
        source: options.source,
        isActivated: { value: options.isActivated },
      };
      const auth = await request.post('/v2/loginByPhone', data);
      setAccessToken(auth.accessToken);
      const { member } = await request.get('/v2/socialMember');
      this._member = member;
    } else if (isWeapp) {
      const data = {
        scope: options.scope || WeappAuthScope.BASE,
        code: options.code,
        watermark: { appid: options.appId },
        is_group: JSON.stringify(options.isGroup || false),
      };

      if (!data.code) {
        const { code } = await wxWeapp.login();
        data.code = code;
      }

      if (wx.getAccountInfoSync) {
        const { miniProgram } = wx.getAccountInfoSync();
        data.watermark.appid = miniProgram.appId;
      } else if (!options.appId) {
        throw new Error('appId is required');
      }

      if (data.scope === WeappAuthScope.USER_INFO) {
        let { iv, encryptedData } = options;
        if (!iv || !encryptedData) {
          const lang = options.lang || 'zh_CN';
          ({ iv, encryptedData } = await wxWeapp.getUserInfo({ lang }));
        }

        data.iv = iv;
        data.encrypted_data = encryptedData;
      }

      const baseURL = `${envConfig.oauthApiBaseUrl}/${accountId}`;
      const auth = await request.post('/v2/weapp/oauth', data, { baseURL });
      setAccessToken(auth.accessToken);
      this._accessToken = auth.accessToken;
      this._member = auth.member;
      this._newChannel = auth.newChannel;
      if (auth.member) {
        const socials = [
          this._member.originFrom || {},
          ...(this._member.socials || []),
        ];
        this._social = socials.find((item) => {
          return item.channel === auth.channelId && item.openId === auth.openId;
        });
      }
    }

    try {
      await request.post('/v2/memberEventLogs/bindAnonymousToMember', { clientId: getClientId() });
    } finally {
      // 多页应用，如果在调了 signin() 后，页面跳转，浏览器则会 cancel 该请求
      // 支持调用方传 callback，确保 signin 成功或者失败后再做后续操作
      if (isObject(options) && isFunction(options.callback)) {
        console.warn('[DEPRECATED] 请尽快使用如下写法代替 callback：\nawait mai.member.signin(options);\n callback();\n或\nmai.member.signin(options).then(callback);');
        options.callback();
      }
    }
  }

  /**
   * 给当前客户打标签，需要授权 {@link Member#signin}
   *
   * @param {Array<string>} tags - 新标签数组，不能为空
   */
  addTags(tags = []) {
    if (!tags.length) {
      throw new Error('tags is required');
    }

    return request.put(
      '/v2/member/tag/add',
      { tags },
    );
  }

  /**
   * 创建活动传播链，需要授权 {@link Member#signin}。比如 A 邀请了 B 参与某项活动，那么活动裂变场景的集成流程：
   * - 用户 A 报名参与活动，生成专属海报后，调用此接口，inviterOpenId 为空。
   * - 用户 B 访问了 A 海报中的链接，调用此接口，inviterOpenId 为 A 的 openId，视为 A 邀请 B 参与活动。
   * - 如果之前 B 已经访问了用户 C 的海报，仍然视为 C 邀请了 B（一个用户只能被邀请一次）。
   *
   * @param {string} affiliateProgramId - 营销活动 ID
   * @param {string} [inviterOpenId] 邀请者 open ID
   */
  async trackAffiliation(affiliateProgramId, inviterOpenId) {
    if (!affiliateProgramId) {
      throw new Error('affiliateProgramId is required');
    }

    await request.post(
      `/v2/marketing/affiliatePrograms/${affiliateProgramId}/track`,
      { inviterOpenId },
    );
  }

  /**
   * 获取客户详情
   *
   * @param {Array<'Card'|'Properties'>} [extraFields=[]] - 控制返回客户扩展字段
   * - Card：返回值中会带有该客户的会员卡信息
   * - Properties：返回值中会带有该客户的属性设置
   * @param {boolean} [withCache=false] - 启用缓存，多次调用会从缓存中读取
   * @returns {Promise<object>}
   */
  getDetail(extraFields = [], withCache = false) {
    const getDetailFromRemote = async () => {
      const memberPromise = request.get('/v2/member', { params: { extraFields } });
      const promises = [memberPromise];
      if (extraFields.includes('Properties')) {
        const propertyPromise = this._getCache(
          'propertyInfo',
          () => this.searchPropertyInfo({ listCondition: { perPage: 999 } }),
        );
        promises.push(propertyPromise);
      }

      const [member, propertyInfoArray] = await Promise.all(promises);
      if (propertyInfoArray) {
        for (const property of member.properties) {
          const valueField = Object.keys(property).find((key) => /^value/.test(key));
          property.value = property[valueField] && property[valueField].value;
          delete property[valueField];

          const propertyInfo = propertyInfoArray.find((info) => info.id === property.id);
          property.propertyId = propertyInfo && propertyInfo.propertyId;

          if (property.propertyId) {
            Object.defineProperty(member.properties, property.propertyId, {
              value: property.value,
              enumerable: false,
            });
          }
        }
      }

      return member;
    };

    const cacheKey = extraFields.sort().join();
    return this._getCache(`getDetail:${cacheKey}`, getDetailFromRemote, !withCache);
  }

  /**
   * 搜索客户属性
   *
   * @param {object} options - 选项
   * @param {boolean} [options.isDefault=null] - 是否是默认客户属性
   * @param {boolean} [options.isVisible=null] - 是否是可见客户属性
   * @param {Array<string>} [options.ids=[]] - 客户属性 ID
   * @param {Array<string>} [options.names=[]] - 属性名称
   * @param {Array<string>} [options.propertyIds=[]] - 自定义属性 ID
   * @param {object} [options.listCondition={}] - 分页选项
   * @param {number} [options.listCondition.page=1] - 页码
   * @param {number} [options.listCondition.perPage=20] - 每页数据数目
   * @param {Array<string>} [options.listCondition.orderBy=[]] - 排序字段。按字段排序，例如 ["createdAt", "-createdAt"]
   * @returns {Promise<Array<object>>}
   */
  async searchPropertyInfo(options = {}) {
    const params = options;
    ['isDefault', 'isVisible', 'isVisibleInFilter'].forEach((fieldKey) => {
      if (options[fieldKey] !== undefined) {
        params[`${fieldKey}`] = { value: options[fieldKey] };
      }
    });

    const { items } = await request.get('/v2/member/properties', { params });
    return items;
  }

  /**
   * 设置客户属性
   *
   * @param {Object[]} properties 客户属性列表
   * @param {string} properties[].propertyId 客户属性 ID
   * @param {string} properties[].type 客户属性类型
   * @param {any} properties[].value 客户属性值
   * @returns {Promise<object>}
   */
  async setProperties(properties = []) {
    const items = [];
    for (const property of properties) {
      const { propertyId, type, value } = property;
      const item = { propertyId };
      const propertyTypeMap = {
        location: 'valueNumberArray',
        images: 'valueArray',
        address: 'valueArray',
        checkbox: 'valueArray',
        date: 'valueDate',
        datetime: 'valueDate',
        number: 'valueNumber',
        currency: 'valueNumber',
        bool: 'valueBool',
      };
      const fieldKey = propertyTypeMap[type] || 'valueString';
      item[fieldKey] = { value };
      items.push(item);
    }

    const result = await request.put('/v2/member', { properties: items });
    return result;
  }

  /**
   * 获取 mai.request 租户 Id
   *
   * @returns {string}
   */
  getAccountId() {
    return request.defaults.headers['X-Account-Id'];
  }

  /**
   * 设置 mai.request 租户 Id
   * @param {string} accountId
   */
  setAccountId(accountId) {
    setAccountId(accountId);
  }

  /**
   * 获取 channel id，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 返回 signin 时选项中 channelId
   *
   * @returns {string}
   */
  getChannelId() {
    return this._social?.channel || '';
  }

  /**
   * 获取手机号, 需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getPhone() {
    return this._member?.phone || '';
  }

  /**
   * 获取 access token，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 返回 signin 时选项中 accessToken
   *
   * @private
   * @returns {string}
   */
  getAccessToken() {
    return this._accessToken;
  }

  /**
   * 获取客户 id，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getMemberId() {
    return this._member?.id || '';
  }

  /**
   * 获取客户是否激活，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {boolean}
   */
  isActivated() {
    return !!(this._member?.isActivated);
  }

  /**
   * 获取客户当前渠道信息，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {object}
   */
  getSocial() {
    return this._social;
  }

  /**
   * 获取客户当前渠道 nickname，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getNickname() {
    return this._social?.nickname || '';
  }

  /**
   * 获取客户当前渠道 openId，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getOpenId() {
    return this._social?.openId || '';
  }

  /**
   * 获取客户当前渠道 unionId，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getUnionId() {
    return this._social?.unionId || '';
  }

  /**
   * 获取客户当前渠道是否授权，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {boolean}
   */
  isSocialAuthorized() {
    return !!(this._social?.authorized);
  }

  /**
   * 更新当前客户信息
   *
   * @private
   * @param {Object} params 需要更新的字段集合
   */
  updateAs = (params) => {
    this._member = { ...this._member, ...params };
  }

  /**
   * 判断用户在当前渠道是否第一次授权
   * - 仅小程序可用
   *
   * @returns {boolean}
   */
  isNewChannel() {
    return this._newChannel;
  }
}

export default Member;
