/* eslint-disable no-console */
import axios from 'axios';
import qs from 'qs';
import weappAdapter from './weappAxiosAdapter/adapter';
import { isWeapp } from './lang';
import { OAUTH_ENV_URL, BUSINESS_API_ENV_URL, CONSUMER_API_ENV_URL, CDN_ENV_URL } from './env';
import localStorage from './localStorage';
import { uuid } from './string';

const axiosOptions = {
  // 确保 params 输出结果如 id[]=1&id[]=2&listCondition.page=1&listCondition.perPage=20
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets', allowDots: true }),
};

if (isWeapp) {
  axiosOptions.adapter = weappAdapter(axios);
}

let clientId = null;

/**
 * 获取客户 clientId 以追踪匿名客户行为
 *
 * @private
 * @returns {string} 随机生成的客户端 UUID
 */
const getClientId = () => {
  if (!clientId) {
    const clientIdKey = 'mai_client_id';
    clientId = localStorage.getItem(clientIdKey);
    if (!clientId) {
      clientId = uuid();
      localStorage.setItem(clientIdKey, clientId);
    }
  }

  return clientId;
};

/**
 * @private
 * 处理 maijs 内部处理网络请求
 */
const request = axios.create({ ...axiosOptions, headers: {} });

/**
 * @private
 * 处理 Staff API
 */
const staffRequest = axios.create({ ...axiosOptions, headers: {} });

/**
 * @private
 * 处理 Consumer API
 */
const outsideRequest = axios.create({ ...axiosOptions, headers: {} });

/**
 * @private
 * 处理 Staff API
 */
const outsideStaffRequest = axios.create({ ...axiosOptions, headers: {} });

/**
 * 环境配置
 *
 * @property {string} env - 环境
 * @property {string} accountId - 租户 Id
 * @property {string} oauthApiBaseUrl - OAuth 网络请求 Base URL
 * @property {string} consumerApiBaseUrl - Consumer API 网络请求 Base URL
 * @property {string} cdnBaseUrl - CDN 资源 Base URL
 */
const envConfig = {
  env: '',
  accountId: '',
  options: {
    appName: '',
    appVersion: '',
  },
  oauthApiBaseUrl: '',
  consumerApiBaseUrl: '',
  businessApiBaseUrl: '',
  cdnBaseUrl: '',
};

const initRequest = (env, accountId, options = {}) => {
  envConfig.env = env;
  envConfig.accountId = accountId;
  envConfig.options = options;
  envConfig.oauthApiBaseUrl = options.oauthApiBaseUrl || OAUTH_ENV_URL[env];
  envConfig.consumerApiBaseUrl = options.consumerApiBaseUrl || CONSUMER_API_ENV_URL[env];
  envConfig.businessApiBaseUrl = options.businessApiBaseUrl || BUSINESS_API_ENV_URL[env];
  envConfig.cdnBaseUrl = options.cdnBaseUrl || CDN_ENV_URL[env];

  // TODO: 以下 oauthAPIDomain、consumerAPIDomain、cdnDomain 为兼容代码，建议半年以后删除
  Object.defineProperty(envConfig, 'oauthAPIDomain', {
    enumerable: false,
    get() {
      console.warn('[DEPRECATED] "oauthAPIDomain" has been deprecated, please update to "oauthApiBaseUrl" ASAP.');
      return envConfig.oauthApiBaseUrl;
    },
  });

  Object.defineProperty(envConfig, 'consumerAPIDomain', {
    enumerable: false,
    get() {
      console.warn('[DEPRECATED] "consumerAPIDomain" has been deprecated, please update to "consumerApiBaseUrl" ASAP.');
      return envConfig.consumerApiBaseUrl;
    },
  });

  Object.defineProperty(envConfig, 'cdnDomain', {
    enumerable: false,
    get() {
      console.warn('[DEPRECATED] "cdnDomain" has been deprecated, please update to "cdnBaseUrl" ASAP.');
      return envConfig.cdnBaseUrl;
    },
  });

  request.interceptors.response.use((res) => {
    if (res.headers && res.headers['x-weapp-version']) {
      envConfig.weappVersion = res.headers['x-weapp-version'];
    }

    return res;
  });

  [
    { rest: request, isStaff: false, isOutside: false },
    { rest: outsideRequest, isStaff: false, isOutside: true },
    { rest: staffRequest, isStaff: true, isOutside: false },
    { rest: outsideStaffRequest, isStaff: true, isOutside: true },
  ].forEach((item) => {
    item.rest.defaults.timeout = 3000;
    item.rest.defaults.headers = {
      'X-Account-Id': accountId,
      'X-Requested-With': 'XMLHttpRequest',
    };

    item.rest.defaults.withCredentials = true;
    item.rest.defaults.params = { maijsVersion: process.env.SDK_VERSION, clientId: getClientId() };
    if (options.appVersion) {
      item.rest.defaults.params.appVersion = options.appVersion;
    }
    if (options.appName) {
      item.rest.defaults.params.appName = options.appName;
    }

    item.rest.defaults.baseURL = item.isStaff ? envConfig.businessApiBaseUrl : envConfig.consumerApiBaseUrl;
    item.rest.interceptors.response.use((res) => {
      // eslint-disable-next-line no-console
      console.debug('request result', { request: res.request, status: res.status, response: res.data });
      if (item.isOutside) {
        return res;
      }

      return res.data;
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error('request error', { error, request: error.request, response: error.response });
      return Promise.reject(error);
    });
  });
};

const setAccessToken = (accessToken) => {
  [request, outsideRequest].forEach((item) => {
    item.defaults.headers['X-Access-Token'] = accessToken;
  });
};

const setStaffAccessToken = (accessToken) => {
  [staffRequest, outsideStaffRequest].forEach((item) => {
    item.defaults.headers['X-Access-Token'] = accessToken;
  });
};

const setAccountId = (accountId) => {
  envConfig.accountId = accountId;
  [request, outsideRequest].forEach((item) => {
    item.defaults.headers['X-Account-Id'] = accountId;
  });
};

const setStaffAccountId = (accountId) => {
  envConfig.accountId = accountId;
  [staffRequest, staffRequest].forEach((item) => {
    item.defaults.headers['X-Account-Id'] = accountId;
  });
};

export {
  initRequest,
  envConfig,
  getClientId,
  setAccountId,
  setAccessToken,
  request,
  outsideRequest,
  setStaffAccountId,
  setStaffAccessToken,
  staffRequest,
  outsideStaffRequest,
};
