const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

const isFunction = (value) => Object.prototype.toString.call(value) === '[object Function]';

const isWeapp = !window && !document && !!wx;

const isQyWeapp = isWeapp && wx.getSystemInfoSync().environment === 'wxwork';

const isQyH5 = () => {
  return !!(navigator && /wxwork/i.test(navigator.userAgent));
};

export {
  isObject,
  isFunction,
  isWeapp,
  isQyH5,
  isQyWeapp,
};
