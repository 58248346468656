import { request, staffRequest } from './utils/request';
import promisify from './utils/promisify';
import { OAUTH_ENV_URL } from './utils/env';
import { isQyH5 } from './utils/lang';

/**
 * 微信 JS-SDK 原生方法集合，如需更新参考[附录2-所有JS接口列表](https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#63)
 * @private
 */
const apis = [
  'updateAppMessageShareData',
  'updateTimelineShareData',
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  'onMenuShareQQ',
  'onMenuShareWeibo',
  'onMenuShareQZone',
  'startRecord',
  'stopRecord',
  'onVoiceRecordEnd',
  'playVoice',
  'pauseVoice',
  'stopVoice',
  'onVoicePlayEnd',
  'uploadVoice',
  'downloadVoice',
  'chooseImage',
  'previewImage',
  'uploadImage',
  'downloadImage',
  'translateVoice',
  'getNetworkType',
  'openLocation',
  'getLocation',
  'hideOptionMenu',
  'showOptionMenu',
  'hideMenuItems',
  'showMenuItems',
  'hideAllNonBaseMenuItem',
  'showAllNonBaseMenuItem',
  'closeWindow',
  'scanQRCode',
  'chooseWXPay',
  'openProductSpecificView',
  'addCard',
  'chooseCard',
  'openCard',
  'openAddress',
];

/**
 * 微信 H5 JS-SDK 封装类，详情参考[JS-SDK说明文档](https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html)
 */
class WxH5 {
  /**
   * 根据最近一次 config 参数生成的 key，如果 key 不变则不会重复 config
   *
   * @private
   * @type {string}
   */
  _cachedConfigOptions = null

  /**
   * this.config 所返回的 Promise
   *
   * @private
   * @type {promise}
   */
  _configReadyPromise = null;

  /**
   * 创建 WxH5 实例
   */
  constructor(env, accountId) {
    // 浏览器中并且未使用 JS-SDK，将所有实例方法置空
    if (window && !window.wx) {
      Object.getOwnPropertyNames(WxH5.prototype).forEach((propertyName) => {
        WxH5.prototype[propertyName] = () => { };
      });
      apis.forEach((api) => {
        this[api] = () => { };
      });
    } else {
      this._env = env;
      this._accountId = accountId;
      apis.map(async (api) => {
        await this._configReadyPromise;
        this[api] = (...args) => promisify(wx[api], ...args);
      });
    }
  }

  /**
   * 权限验证配置
   *
   * @param {object} options - 权限验证配置
   * @param {string[]} [options.jsApiList=[]] - 需要使用的 JS 接口列表
   * @param {string[]} [options.openTagList=[]] - 需要使用的开放标签列表，例如['wx-open-launch-app']
   * @param {string} [options.url] - 当前网页的 URL，不包含 # 及其后面部分。默认会从当前路径截取
   * @param {string} [options.channelId] - 指定签名所用的 channelId。默认会从 token 中获取
   * @param {string} [options.isNotDelegated] - 是否不使用服务商模式
   * @param {boolean} [options.debug=false] - 是否开启调试模式
   * @param {boolean} [options.wxwork] - 是否初始化企业微信 js-sdk，如果为 false 则初始化微信 js-sdk，如果为空则根据 navigator.userAgent 判断
   * @returns {promise}
   */
  async config(options = {}) {
    if (!Object.keys(options).length || JSON.stringify(options) === JSON.stringify(this._cachedConfigOptions)) {
      return this._configReadyPromise;
    }

    try {
      this._cachedConfigOptions = options;
      this._configReadyPromise = new Promise((resolve, reject) => {
        this._resolveConfigReady = resolve;
        this._rejectConfigReady = reject;
      });

      const {
        jsApiList = [],
        openTagList = [],
        debug = false,
        url = window.location.href.split('#')[0],
        channelId,
        isNotDelegated = false,
        wxwork = isQyH5(),
      } = options;

      if (wxwork) {
        let params = { url, channelId };
        const signatureConfig = await staffRequest.get('/v2/wechatcp/jssdk/signature', { params });
        await new Promise((resolve, reject) => {
          wx.ready(resolve);
          wx.error(reject);
          wx.config({
            beta: true,
            debug,
            appId: signatureConfig.corpId,
            timestamp: signatureConfig.timestamp,
            nonceStr: signatureConfig.nonceStr,
            signature: signatureConfig.signature,
            jsApiList: [
              ...jsApiList,
              'checkJsApi',
              'agentConfig',
            ],
          });
        });

        params = { channelId, url, type: 'agent_config' };
        const agentSignature = await mai.staffRequest.get('/v2/wechatcp/jssdk/signature', { params });
        await new Promise((resolve, reject) => {
          wx.agentConfig({
            corpid: agentSignature.corpId,
            agentid: agentSignature.appId,
            timestamp: agentSignature.timestamp,
            nonceStr: agentSignature.nonceStr,
            signature: agentSignature.signature,
            jsApiList: [...jsApiList],
            success() {
              resolve();
            },
            fail(error) {
              // eslint-disable-next-line
              console.warn('agentConfig failed', agentSignature, error);
              reject(error);
            },
          });
        });
      } else {
        const params = { url, channelId, isNotDelegated };
        const signatureConfig = await request.get('/v2/wechat/jssdk/signature', { params });
        this._appId = signatureConfig.appId;
        await new Promise((resolve, reject) => {
          wx.ready(resolve);
          wx.error(reject);
          wx.config({
            debug,
            jsApiList: [...jsApiList], // config 会修改 jsApiList 里的值，需要做一次 copy
            openTagList,
            ...signatureConfig,
            appId: this._appId,
          });
        });
      }
      if (this._resolveConfigReady) {
        this._resolveConfigReady();
      }

      return null;
    } catch (error) {
      this._cachedConfigOptions = null;
      if (this._rejectConfigReady) {
        this._rejectConfigReady(error);
      }

      throw error;
    } finally {
      this._configReadyPromise = null;
      this._rejectConfigReady = null;
      this._resolveConfigReady = null;
    }
  }

  /**
   * 自定义“分享给朋友”和“分享到朋友圈”按钮的分享内容
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.desc - 分享描述
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   * @returns {promise}
   */
  updateShareData(options) {
    const params = { ...options };
    params.link = this.generateOAuthLink(options);
    const items = this._cachedConfigOptions
    ?.jsApiList
    ?.filter((item) => /^update.*ShareData$/.test(item))
    ?.map((item) => {
      // eslint-disable-next-line no-console
      console.log(`mai.wxH5.${item}`, params);
      return this[item] && this[item](params);
    }) || [];

    return Promise.all(items);
  }

  /**
   * 自定义 “分享给朋友”、“分享到朋友圈”、“分享到 QQ”及“分享到 QQ 空间”按钮的分享内容
   *
   * **注意：这个接口即将被微信废弃，但目前这是唯一能获取用户分享事件的接口，故依然提供其封装**
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.desc - 分享描述
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {Function} options.success - 分享成功回调
   * @param {string} [options.type='link'] - 分享类型，music、video 或 link
   * @param {string} [options.dataUrl] - 如果分享类型是 music 或 video，则要提供相关数据链接
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   */
  onMenuShare(options) {
    const params = { ...options };
    params.link = this.generateOAuthLink(options);
    const items = this._cachedConfigOptions
      ?.jsApiList
      ?.filter((item) => /^onMenuShare/.test(item))
      ?.map((item) => {
        // eslint-disable-next-line no-console
        console.log(`mai.wxH5.${item}`, params);
        return this[item] && this[item](params);
      }) || [];

    return Promise.all(items);
  }

  /**
   * 生成带 OAuth 的分享链接
   *
   * @param {object} options - OAuth 参数
   * @param {string} options.link - 要分享的链接，完整 URL 或指定哈希后面的部分即可
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   * @returns {string} 带 OAuth 的分享链接
   */
  generateOAuthLink(options = {}) {
    const { link, needOauth = true, oauthConfig } = options;

    const pageLink = /^https?:\/\//.test(link) ? link : `${window.location.href.split('#')[0]}#/${link}`;
    if (!needOauth) {
      return pageLink;
    }

    const { scope, isTest = false, ...othersConfig } = oauthConfig;
    const oAuthQuery = { scope, is_test: isTest, ...othersConfig };
    const queryString = Object.keys(oAuthQuery).reduce((res, key) => `${res}${key}=${oAuthQuery[key]}&`, '');
    const oAuthURL = `${OAUTH_ENV_URL[this._env]}/${this._accountId}/v2/wechat/oauth`;
    return `${oAuthURL}?appid=${this._appId}&${queryString}redirect_url=${encodeURIComponent(pageLink)}`;
  }

  /**
   * 批量剔除无用 key
   *
   * @private
   * @param {object} obj - 原始对象
   * @param {array} uselessKeys - 要剔除的 keys
   * @returns {object} 剔除无用 key 后的对象
   */
  _omit(obj, uselessKeys) {
    return Object.keys(obj).reduce((res, key) => (uselessKeys.includes(key) ? res : { ...res, [key]: obj[key] }), {});
  }
}

export default WxH5;
